<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置： 我的预约</div>
			<div class="wapper">
				<el-row :gutter="20">
					<el-col :span="12" v-for="(item,index) in list" :key="index">
						<div class="content">
							<div class="top flex">
								<span :class="item.type > 1 ? 'zhengwu' : ''">{{item.typeStr}}</span>
								<div class="flex"><img :src="item.auditStatus == 0 ? shenhe : (item.auditStatus == 1 ? tongguo : butongguo)"/>{{item.auditStatusStr}}</div>
							</div>
							<div class='info flex_a'>
								<div class="left">
									<p>姓名：{{item.name}}</p>
									<p>手机号码：{{item.phone}}</p>
									<p>预约日期：{{item.bookingDate}}</p>
									<p>预约时段：{{item.bookingPeriodStr}}</p>
								</div>
								<div class="right">
									<el-button size="small" type="primary" @click="toDetail(item.id)">预约详情</el-button>
								</div>
							</div>
						</div>
					</el-col>
					
				</el-row>
				<img class="noData" v-if="total == 0" src="../../assets/images/zanwujilu.png"/>
				<el-pagination
					background
					v-if="total > 0"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageNo"
					:page-size="pageSize"
					layout="total, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>	

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				tongguo: require('../../assets/images/tongguo.png'),
				butongguo: require('../../assets/images/butongguo.png'),
				shenhe: require('../../assets/images/shenhe.png'),
				list: [],
				total: '',
				pageNo: 1,
				pageSize: 10,
				
			}
		},
		created() {
			
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				let formData = new FormData()
					formData.append('page', this.pageNo)
					formData.append('limit', this.pageSize)
					formData.append('usersId', JSON.parse(localStorage.getItem('userInfo')).id)
				this.$http({
					url: `/bookingRecordApi/queryBookingRecordByUsersId`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.total = res.count
						this.list = res.data
					}
					
				});
			},
			toDetail(id) {
				this.$router.push({ path: "/mine/detail",query:{id: id}});
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.getList()
			},
			handleCurrentChange(val) {
				this.pageNo = val
				this.getList()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		background: #FFF;
		.wapper{
			padding: 30px 60px;
			.el-col{
				margin-bottom: 20px;
				.content{
					background: url('../../assets/images/wodeyuyuekuang.png') no-repeat;
					height: 207px;
					padding-right: 25px;
				}
				.top{
					span{
						color:#A5343C;
						background: rgb(253, 226, 226);
						border-radius: 2px;
						font-size: 12px;
						padding: 5px;
					}
					.zhengwu{
						color: #B69A5D;
						background: rgba(182,154,93,.2);

					}
					.flex{
						margin-left: auto;
						align-items: center;
					}
					img{
						width: 17px;
						height: 17px;
						margin-right: 5px;
					}
				}
				.info{
					margin-top: 15px;
					padding-left: 30px;
					.left{
						p{
							line-height: 35px;
						}
					}
					.right{
						margin-left: auto;
					}
				}

			}
		}
	}
	
	
	
}
	
</style>
